<template>
  <div class="order-item">
    <h3 class="order-item-header clearfix">
      <div class="text-left">{{$t('orders.labelOrderId')}}: {{order.orderId}}</div>
      <div class="hidden-xs">{{$t('orders.labelCreatedAt')}}: {{createdAt}}</div>
      <div class="text-right">
        <button class="text-gray" @click="$emit('delete')" v-if="!order.paid">
          <i class="ion-trash-a"></i>
        </button>
      </div>
    </h3>
    <div class="order-item-body container-fluid">
      <div class="row">
        <div class="col-xs-4 col-sm-2 text-center">
          <cover :book="order.book"></cover>
        </div>
        <div class="col-xs-4 col-sm-4 padding-4 order-item-info">
          <p class=" text-ellipsis">{{$t('orders.labelBookName')}}: {{order.book.design.title}}</p>
          <p>{{$t('book.pages')}}: {{order.book.totalPage}}</p>
          <p v-if="order.bookType !== 'diarybook'">{{$t('orders.labelType')}}: {{order.book.spoiler}}</p>
          <p v-if="order.bookType === 'diarybook'">{{$t('orders.labelBinding')}}: {{$t(binding)}}</p>
          <p>{{$t('orders.labelQuantity')}}: {{order.quantity}}</p>
        </div>
        <div class="col-xs-4 col-sm-3 padding-4 text-center hidden-xs">
          <template v-if="order.paid">{{$t('orders.labelPaid')}}：{{getSymbol(order.currency)}}{{order.paidMoney}}</template>
          <span class="text-orange" v-else>
            {{$t('orders.labelPending')}}
          </span>
        </div>
        <div class="col-xs-4 col-sm-3 padding-4 text-center">
          <p v-if="order.bookType === 'diarybook'">
            <router-link :to="{name: 'order-check', query: {bid: order.bid, binding: order.binding, bookType: order.bookType}}">
              <button class="btn red small">{{$t('orders.buttonBuy')}}</button>
            </router-link>
          </p>
          <p>
            <template v-if="order.bookType !== 'diarybook'">
              <a :href="`https://ipastbook.com/app/design/album?aid=${order.bid}&type=${order.bookType}`" target="_blank">
                <button class="btn red small">{{$t('orders.buttonOrderPreview')}}</button>
              </a>
            </template>
            <router-link :to="{name: 'preview', query: {bid: order.bid, locked: 1}}" v-else>
              <button class="btn red small">{{$t('orders.buttonOrderPreview')}}</button>
            </router-link>
          </p>
          <button class="text-primary text-small" @click="$emit('check')">
            {{$t('orders.buttonOrderInfo')}}
          </button>
        </div>
      </div>
    </div>
    <div class="order-item-footer visible-xs clearfix">
      <div class="fl">
        <template v-if="order.paid">{{$t('orders.paid')}}：{{getSymbol(order.currency)}}{{order.paidMoney}}</template>
        <span class="text-orange" v-else>
          {{$t('orders.unPaid')}}
        </span>
      </div>
      <div class="fr text-right" v-if="order.paid">
        <button class="btn default small" @click="FBInvite($store.state.user.uid)">{{$t('orders.share')}}</button>
        <router-link :to="{name: 'order-check', query: {bid: order.bid, binding: order.binding, bookType: order.bookType}}">
          <button class="btn default-red small">{{$t('orders.goPaid')}}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import generateCover from 'src/_utils/generateCover'
import config from '../../app.config'
import currency from 'src/mixins/currency'
import FBshare from 'src/mixins/FBShare'
import Cover from '../../components/Cover'

export default {
  name: 'order-item',
  components: { Cover },
  mixins: [currency, FBshare],
  props: {
    order: {
      type: Object,
      default: function () {
        return {
          orderId: '12356',
          createdAt: '2019/12/29',
          design: {
            title: '',
            author: '哈哈'
          }
        }
      }
    }
  },
  data () {
    return {
      imageLoaded: false
    }
  },
  computed: {
    cover () {
      const { book, bookType } = this.order
      const { design, fromDate, toDate } = book
      const chapters = [{ month: fromDate }, { month: toDate }]
      return generateCover({ design, chapters, isCalendar: bookType === 'calendar' })
    },
    coverHeight () {
      if (this.order.bookType === 'photobook') {
        return 77.6
      }
      return 141.1
    },
    binding () {
      let binding = ''
      config.bindings.map(item => {
        if (item.name === this.order.binding) {
          binding = item.desc
        }
      })
      binding = binding || 'common.text.paperback'
      return binding
    },
    createdAt () {
      return this.$moment(this.order.createdAt).format('YYYY/MM/DD')
    }
  },
  created () {
  }
}
</script>

<style lang="scss">

  .order-item {
    border: 1px solid #d3dce6;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      .padding-10 {
        padding-left: 10px;
        padding-right: 10px;
      }
      .padding-4 {
        padding-left: 4px;
        padding-right: 4px;
      }
      .order-item-header {
        border: 0;
      }
    }
  }
  .order-item-header {
    margin: 0;
    padding: 16px;
    border-bottom: 1px solid #d3dce6;
    display: flex;
    font-size: 14px;
    > div {
      flex: 1;
    }
  }
  .order-item-body {
    position: relative;
    .order-cover {
      position: absolute;
      top:0;
      left: 15px;
      right: 15px;
      padding-bottom: 100%;
      box-shadow: $dp-2;
      width: calc(100% - 30px);
      &.load {
        position: relative;
        left: auto;
        right: auto;
        padding: 0;
        width: 100%;
      }
    }
    .order-item-info {
    }
  }
  .order-item-footer {
    border-top: 1px solid #d3dce6;
  }
  .order-item-header, .order-item-body, .order-item-footer {
    padding: 16px;
    @media (max-width: 767px) {
      padding: 12px;
    }
  }
</style>
