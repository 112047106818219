<template>
  <div class="order-detail container-fluid">
    <h4 class="text-center">{{$t('orders.labelOrderInfo')}}</h4>
    <div v-if="binding !== 'book.bindingPdf'" class="order-address line-block">
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelAddressee')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.address?.firstName}} {{order.address?.lastName}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelPhone')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.address?.phone}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelAddress')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">{{address}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelPostalCode')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.address?.postalCode}}</div>
      </div>
      <template v-if="order.paid">
        <div class="row">
          <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelDelivery')}}: </div>
          <div class="padding-lane col-xs-8 col-sm-9">
            <template v-if="order.shipCompany">
              {{$t('orders.labelSf')}}
            </template>
            <template v-else>
              {{$t('orders.labelUnship')}}
            </template>
          </div>
        </div>
        <div class="row" v-if="order.shipNo">
          <div class="padding-lane col-xs-4 col-sm-3 text-right">
            {{$t('orders.labelDeliveryNo')}}:
          </div>
          <div class="padding-lane col-xs-8 col-sm-9">
            <a target="_blank" class="text-primary" title="查詢運單信息"
               :href="`https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${order.shipNo}`">
              {{order.shipNo}}
            </a>
            <small>(點擊查詢運單詳情)</small>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelPayment')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.payMethod}}</div>
      </div>
    </div>
    <div class="order-address line-block" v-else>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelAddress')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">
          {{order.pdfEmail}}
        </div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.pdfDownload')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9 text-primary" style="cursor: pointer;" @click="clickPdfLink">{{order.status === 3 ? $t('orders.pdfLink') : $t('orders.pdfGenerate')}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelPayment')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.payMethod}}</div>
      </div>
    </div>
    <div class="order-book line-block row">
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelBookName')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.book.design.title}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelQuantity')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.quantity}}</div>
      </div>
      <template v-if="order.bookType === 'diarybook'">
        <div class="row">
          <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelType')}}:</div>
          <div class="padding-lane col-xs-8 col-sm-9">
            {{$t(typeset)}}
          </div>
        </div>
        <div class="row">
          <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelBinding')}}:</div>
          <div class="padding-lane col-xs-8 col-sm-9">{{$t(binding)}}</div>
        </div>
      </template>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelPrice')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{getSymbol(order.currency)}}{{order.totalPrice}}</div>
      </div>
    </div>
    <div class="line-block row">
      <template v-if="order.couponNo">
        <div class="row" >
          <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelCoupon')}}:</div>
          <div class="padding-lane col-xs-8 col-sm-9">{{order.couponNo}}</div>
        </div>
        <div class="row">
          <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelDiscount')}}:</div>
          <div class="padding-lane col-xs-8 col-sm-9">{{getSymbol(order.currency)}}{{order.couponPrice}}</div>
        </div>
      </template>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelNote')}}:</div>
        <div class="padding-lane col-xs-8 col-sm-9">{{order.note}}</div>
      </div>
      <div class="row">
        <div class="padding-lane col-xs-4 col-sm-3 text-right">{{$t('orders.labelCreatedAt')}}: </div>
        <div class="padding-lane col-xs-8 col-sm-9">{{createdAt}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../app.config'
import currency from 'src/mixins/currency'

export default {
  name: 'order-info',
  mixins: [currency],
  props: {
    order: {
      type: Object,
      default: function () {
        return {
          orderId: '12356',
          createdAt: '2019/12/29',
          design: {
            title: 'iPastBook',
            author: 'xinshu'
          }
        }
      }
    }
  },
  computed: {
    createdAt () {
      return this.$moment(this.order.createdAt).format('YYYY/MM/DD')
    },
    binding () {
      let binding = ''
      config.bindings.map(item => {
        if (item.name === this.order.binding) {
          binding = item.desc
        }
      })
      binding = binding || 'common.text.hardback'
      return binding
    },
    typeset () {
      let typeset = ''
      config.templates.map(item => {
        if (item.name === this.order.printType) {
          typeset = item.desc
        }
      })
      typeset = typeset || 'common.text.a5Waterfall'
      return typeset
    },
    address () {
      const { address } = this.order
      if (!address) {
        return ''
      }
      return address.countryCode + address.state + address.city + address.line1 + address.line2
    }
  },
  methods: {
    clickPdfLink () {
      if (this.order.status !== 3) {
        return
      }
      this.$router.push({ path: '/app/pdf', query: { oid: this.order.orderId } })
    }
  }
}
</script>

<style lang="scss">
.order-detail {
  font-size: 14px;
  padding: 0 10px;
  overflow: hidden;
  margin-bottom: 2rem;
  .padding-lane {
    &:empty {
      content: '&nbsp;';
      height: auto;
    }
    margin-bottom: 6px;
  }
  .line-block {
    padding: 10px 0;
    border-bottom: 1px solid #9e9e9e;
    @media (max-width: 767px) {
      .padding-lane {
        padding-left: 8px;
        padding-right: 8px;
      }
      > div {
        padding-left: 8px;
        padding-right: 8px;

      }
    }
  }
}
</style>
