<template>
  <div class="order-list container">
    <div class="row">
      <div class="col-xs-2 hidden-xs"/>
      <div class="col-xs-12 col-sm-8">
        <!--加载状态-->
        <div class="text-center" v-if="!ordersLoaded">
          <div class="loading-icon"></div>
        </div>
        <!--空订单-->
        <div class="text-center" v-if="ordersLoaded && !orders.length">
          <p>{{$t('orders.tipEmpty')}}</p>
          <p>
            <router-link to="/app/shelf">
              <button class="btn primary">{{$t('orders.buttonShelf')}}</button>
            </router-link>
          </p>
        </div>
        <!--订单列表-->
        <template v-if="ordersLoaded && orders.length">
          <order-item v-for="(order, index) in orders" :key="index"
                      :order="order"
                      @check="checkOrder(order)" @delete="deleteOrder(order.orderId)"/>
        </template>
      </div>
      <div class="col-xs-2 hidden-xs"/>
      <popup :show="showDetail" @close="showDetail=false">
        <order-info slot="body" :order="activeOrderInfo"></order-info>
      </popup>
    </div>
  </div>
</template>

<script>
import api from 'src/api/'
import OrderItem from './OrderItem.vue'
import { mapGetters, mapActions } from 'vuex'
import OrderInfo from './OrderInfo.vue'
import { findIndex } from 'lodash'
import generateCover from '../../_utils/generateCover'

export default {
  name: 'order-list',
  components: { OrderItem, OrderInfo },
  data () {
    return {
      orders: [],
      ordersLoaded: false,
      showDetail: false,
      activeOrderInfo: {}
    }
  },
  created () {
    this.fetchOrders(this.user.uid)
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    fetchOrders (uid) {
      return api.fetchOrders(uid).then(data => {
        this.ordersLoaded = true
        if (!data || data.errors) {
          this.$toast({
            message: this.$t('orders.tipLoadFailed'),
            position: 'bottom'
          })
          return
        }
        data = data.map(order => {
          const { book, bookType } = order
          const { design, cover, toDate, fromDate, templateStyle } = book

          book.type = bookType
          if (bookType === 'calendar') {
            if (!book.totalPage) {
              book.totalPage = 13
            }
            if (!design) {
              book.design = {
                ...cover,
                cover: cover,
                template: cover.coverTemplate
              }
            }
          }

          const chapters = [{ month: fromDate }, { month: toDate }]
          book.coverUrl = generateCover({ design: book.design, chapters, isCalendar: bookType === 'calendar' })
          let style
          switch (bookType) {
            case 'photobook':
              style = '簡約版'
              if (templateStyle === 'magazine-1' && templateStyle.includes('travel')) {
                style = '旅行風'
              }
              if (templateStyle.includes('love')) {
                style = '爱情風'
              }
              if (templateStyle.includes('japan')) {
                style = '日系風'
              }
              if (templateStyle.includes('child')) {
                style = '童趣版'
              }
              if (templateStyle.includes('baby')) {
                style = '幼兒版'
              }
              book.spoiler = style
              if (book.template === 'album') {
                book.spoiler = 'A4雜誌冊'
              }
              break
            case 'calendar':
              book.spoiler = '簡約版月曆'
              break
          }
          return order
        })
        this.orders = data.reverse()
      })
    },
    checkOrder (order) {
      this.showDetail = true
      this.activeOrderInfo = order
    },
    deleteOrder (orderId) {
      this.$confirm({
        title: this.$t('orders.labelDeleteOrder'),
        cancelText: this.$t('button.delete'),
        okText: this.$t('button.cancel'),
        cancelClass: 'text-red',
        okClass: 'text-dark',
        onCancel: (modal) => {
          modal.close()
          this.setLoading({
            text: this.$t('orders.labelDeleteOrderLoading'),
            visible: true
          })
          api.deleteOrder(orderId).then(data => {
            this.setLoading(false)
            if (!data || data.errors) {
              this.$toast({
                message: this.$t('orders.labelDeleteOrderFailed'),
                position: 'bottom'
              })
              return
            }
            const currentIndex = findIndex(this.orders, { orderId })
            this.orders.splice(currentIndex, 1)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.order-list {
  margin-top: 2rem;
  .popup-content {
    @media (max-width: 767px) {
      top: 0;
    }
  }
}
</style>
